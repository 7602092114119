/* styles/fonts.css */
@font-face {
  font-family: 'Radio Canada';
  src: url('./fonts/Radio_Canada/static/RadioCanada-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Radio Canada';
  src: url('./fonts/Radio_Canada/static/RadioCanada-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
